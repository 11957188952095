import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  VisuallyHidden,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Divider,
} from "@chakra-ui/react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { event } from "nextjs-google-analytics";
import { useState, Dispatch, useEffect } from "react";
import { useForm } from "react-hook-form";
import AlertModal from "../../misc/AlertModal";
import { useRouter } from 'next/navigation';
import { useVerifyRecaptcha } from "../../../utils/hooks";

interface RequestInfoModalProps {
  onClose: () => void;
  isOpen: boolean;
  trip: TripSearchResult | SmallGroupTripSearchResult | CustomTripSearchResult;
  globalState: GlobalState;
  setGlobalState: Dispatch<React.SetStateAction<GlobalState>>;
  dontBlockScrollOnMount?: boolean;
  tripType?: string[];
}

interface RequestInfoFormData {
  name: string;
  email: string;
  phone: string;
  size: number | null;
}

const RequestInfoModal = (props: RequestInfoModalProps): JSX.Element => {
  const { register, handleSubmit, formState, reset, setValue } = useForm<RequestInfoFormData>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      size: null,
    }
  });
  
  const router = useRouter();

  const verifyRecaptcha = useVerifyRecaptcha();

  useEffect(() => {
    setValue('email', props.globalState.user?.email ?? '');
    setValue('name', props.globalState.user?.name ?? '');
  }, [props.globalState.user, setValue])

  const [confirmModalData, setConfirmModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: `We have received your information, and someone will be following up with you soon. Thanks!`
  });

  const confirmModal = useDisclosure();

  const submitRequestForm = async (values) => {
    const currentPage = window ? window.location.pathname + window.location.search + window.location.hash : '';

    const submitData = {
      ...values,
      location: props.trip.theLocation,
      type: 'info-request',
      comments: `!!! Info Request: ${props.trip.limitedAvailability ? "LIMITED AVAILABILITY " : ""} Trip to ${props.trip.theLocation} on ${props.trip.tripdate} at $${props.trip.cost}.00`,
      organization: '',
      analyticsInfo: {
        currentPage: currentPage,
        source: '-',
        medium: '-',
        campaign: '-',
        term: '-',
        content: '-',
        numVisits: 0
      }
    }

    let hasLeaderTrips = props.tripType && (props.tripType.includes('leader') || props.tripType.includes('leaderMission') || props.tripType.includes('leaderSponsor'));
    let hasDisasterTrips = props.tripType && props.tripType.includes('disaster');
    let hasSmallGroupTrips = props.tripType && props.tripType.includes('smallgroup');

    const verifyToken = await verifyRecaptcha("request_info_submit");

    if (verifyToken.ok) {
      if (values.size < 5 && (!props.tripType || (!hasSmallGroupTrips && !hasLeaderTrips && !hasDisasterTrips))) {
        router.push('/small-groups-and-individuals');
      } else {
        try {
          const sendContactForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/contact`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
          })
    
          if (sendContactForm.ok) {
            setConfirmModalData({
              icon: ['far', 'circle-check'] as IconProp,
              iconColor: 'brand.green.600',
              title: 'Got It!',
              content: `We have received your information, and someone will be following up with you soon. Thanks!`
            });
    
            props.onClose();
            confirmModal.onOpen();
            reset();
    
            if (submitData.size > 1) {
              event('group_lead', {
                category: submitData.type,
                label: submitData.size
              });
            } else {
              event('individual_lead', {
                category: submitData.type,
                label: submitData.size
              });
            }
    
    
          } else {
            console.error(sendContactForm);
            throw new Error('Failed to send info request form')
          }
    
          confirmModal.onOpen();
    
        } catch (error) {
          console.error(error);
    
          setConfirmModalData({
            icon: ['fas', 'xmark'] as IconProp,
            iconColor: 'red.400',
            title: 'Oops!',
            content: 'Sorry, but there was an error submitting your information. Please try again later.'
          });
    
          confirmModal.onOpen();
        }
      }
    }
  }

  return (
    <>
      <AlertModal
        onClose={confirmModal.onClose}
        isOpen={confirmModal.isOpen}
        iconColor={confirmModalData.iconColor}
        icon={confirmModalData.icon}
        title={confirmModalData.title}
        content={confirmModalData.content}
        dontBlockScrollOnMount={true}
      />

      <Modal onClose={props.onClose} isOpen={props.isOpen} size="lg" blockScrollOnMount={!props.dontBlockScrollOnMount}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center w="100%" flexDirection="column">
              <Box
                color='brand.green.600'
                my="4"
              >
                <FontAwesomeIcon
                  icon={'info-circle'}
                  size={'3x' as SizeProp}
                />
              </Box>
              <Text as="h2">
                Request Info
              </Text>
            </Center>
          </ModalHeader>

          <ModalBody>
            {!props?.trip?.limitedAvailability ?
              (
                <Text as="p" textAlign={'center'} textColor="brand.darkgray.600">
                  If you would like more information about this trip date, drop in your information and
                  one of our trip consultants will follow up with you. Thanks!
                </Text>
              )
              : (
                <Text as="p" textAlign={'center'} textColor="brand.darkgray.600">
                  This date currently has limited availability in this location. Fill out the info form 
                  here to connect with one of our trip consultants.
                </Text>
              )
            }


            <Divider my="2" />

            <form onSubmit={handleSubmit(submitRequestForm)}>
              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['name']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'name'}>
                  Full Name
                </FormLabel>

                <Input
                  name={'name'}
                  type={'text'}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Full Name'}
                  {...register('name', { required: 'Name is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['name']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['email']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'email'}>
                  Email Address
                </FormLabel>

                <Input
                  name={'email'}
                  type={'email'}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Email Address'}
                  {...register('email', { required: 'Email is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['name']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['phone']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'phone'}>
                  Phone Number
                </FormLabel>

                <Input
                  name={'phone'}
                  type={'phone'}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Phone Number'}
                  {...register('phone', { required: 'Phone is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['phone']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['size']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'phone'}>
                  Group Size (estimate)
                </FormLabel>

                <Input
                  name={'size'}
                  type={'number'}
                  min={0}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Group Size'}
                  {...register('size', { required: 'Group size is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['size']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                width="full"
                colorScheme={'brand.orange'}
                variant={'solid'}
                leftIcon={<FontAwesomeIcon icon={['fas', 'info-circle']} />}
                disabled={formState.isSubmitting}
                mb={5}
              >
                Request Info
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RequestInfoModal;